jQuery(function($) {
	
	var Zbir_Scripts = {

		clipboard: function() {
			const copyCard = document.querySelectorAll(".copy-block");
			copyCard.forEach((btn) =>
				btn.addEventListener("click", function (e) {
					const value = this.querySelector('.copy-value');
					if (value) {
						navigator.clipboard.writeText(value.innerText);
						this.classList.add("active");
						setTimeout(function () {
							btn.classList.remove("active");
						}, 1500);
					}
				})
			);
		},

		collapseBlock: function() {
			let parent = $('.about');
			let container = $('.about__content-wrap');
			let text = $('.about__content');
			let containerHeight = container.height();
			let textHeight = text.height();

			if (textHeight > containerHeight && window.innerWidth < 768) {
				parent.addClass('collapse');
				parent.on('click.collapse', function () {
					$(this).toggleClass('open');
					if($(this).hasClass('open')){
						container.css('max-height', textHeight);
					} else {
						container.css('max-height', containerHeight);
					}
				});
			} else {
				parent.removeClass('collapse');
			}
		},

		dropdown: function() {
			let btn = $('.payment-multi-block__head');
			let list = $('.payment-multi-block__body');

			btn.click(function(){
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					list.slideUp(300);
				} else {
					btn.removeClass('active');
					list.slideUp(300);
					$(this).addClass('active');
					$(this).siblings(list).slideDown(300);
				}
			});
		},

		init: function() {
			Zbir_Scripts.clipboard();
			Zbir_Scripts.collapseBlock();
			Zbir_Scripts.dropdown();
		}

	};

	$(window).resize(function() {
		$('.about').off('click.collapse');
		$('.about').removeClass('open');
		$('.about__content-wrap').removeAttr('style');
		Zbir_Scripts.collapseBlock();
	});

	$(document).ready(function() {
		Zbir_Scripts.init();
	});

});